export default function getOGD(that) {
  let response = null
  try {
    response = that.$http.get(`${that.apiUrl}caisse/ogd-list`)
  } catch (error) {
    console.log(error)
  }
  return response
}

export function getTypeAssurance(that) {
  let response = null
  try {
    response = that.$http.get(`${that.apiUrl}caisse/type-assurance`)
  } catch (error) {
    console.log(error)
  }
  return response
}

export function getPrestation(that) {
  let response = null
  try {
    response = that.$http.get(`${that.apiUrl}caisse/prestation`)
  } catch (error) {
    console.log(error)
  }
  return response
}

export function getActes(that) {
  let response = null
  try {
    response = that.$http.get(`${that.apiUrl}acte/`)
  } catch (error) {
    console.log(error)
  }
  return response
}

export function getMedecins(that) {
  let response = null
  try {
    response = that.$http.get(`${that.apiUrl}user/medecin`)
  } catch (error) {
    console.log(error)
  }
  return response
}

export function getResidences(that) {
  let response = null
  try {
    response = that.$http.get(`${that.apiUrl}patient/residences`)
  } catch (error) {
    console.log(error)
  }
  return response
}

export function getPatients(that) {
  let response = null
  try {
    response = that.$http.get(`${that.apiUrl}patient/`)
  } catch (error) {
    console.log(error)
  }
  return response
}

export function getTickets(that, filters = {}) {
  let response = null
  let optionString
  let firstFilter
  // eslint-disable-next-line no-prototype-builtins
  if (Object.keys(filters).length) {
    optionString = '?'
    firstFilter = true
  }
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const key in filters) {
    console.log(`${key} : ${filters[key]}`)
    if (firstFilter) {
      firstFilter = false
    } else {
      optionString += '&'
    }
    optionString += `${key}=${filters[key]}`
  }
  try {
    // response = that.$http.get(`${that.apiUrl}caisse/tickets?date_debut=${filters.date_debut}&date_fin=${filters.date_fin}`)
    response = that.$http.get(`${that.apiUrl}caisse/tickets${optionString}`)
  } catch (error) {
    console.log(error)
  }
  return response
}

export function postCaisseData(that, data) {
  let response = null
  try {
    response = that.$http.post(`${that.apiUrl}caisse/save-caisse-data`, data)
  } catch (error) {
    console.log(error)
  }
  return response
}

// export function dataChecker(data,){
//
// }

export function isEmpty(obj) {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      return false
    }
  }
  return true
}

export function areMethodsValid(obj) {
  if (isEmpty(obj)) return false
  // eslint-disable-next-line no-restricted-syntax,no-labels
  for (const prop in obj) {
    if (!obj[prop]) {
      return false
    }
  }
  return true
}
